@import '../../styles/colors.scss';

.container {
  position: relative;
  user-select: none;

  video {
    background: $positive;
    border-radius: 15px;
    box-shadow: 0 0 5vw 0 rgba(0, 0, 0, 0.5);

    width: 358px;
    height: 320px;
  }

  img {
    background: $negative;

    position: absolute;
    top: 0;
    right: -20%;

    border-radius: 15px;
    box-shadow: 0 0 2.5vw 0 rgba(0, 0, 0, 0.5);

    z-index: -1;
    transform: scale(0.9);

    width: 358px;
    height: 320px;
  }

  img:nth-child(1) {
    background: $neutral;

    top: 0;
    right: -35%;
    z-index: -2;
    box-shadow: 0 0 1.25vw 0 rgba(0, 0, 0, 0.5);

    transform: scale(0.8);
  }
}
