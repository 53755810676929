.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 2vh;

  @media (min-width: 1010px) {
    justify-content: flex-start;
  }
}

.button {
  display: block;
  padding: 0;
  width: 10rem;
  opacity: 0.5;
  filter: grayscale(100%);

  @media (min-width: 1010px) {
    margin-right: 1rem;
  }

  img {
    width: 100%;
    height: 100%;
    transition: transform 0.25s;

    &:hover {
      transform: scale(1.025);
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    }
  }
}
