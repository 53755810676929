@import '../styles/colors.scss';

.section {
  background: $orange;
  width: 100%;
  text-align: center;
}

.inApp {
  background: #fff;
}
