$orange: rgba(250, 204, 185, 0.95);
$orange-red: #db4255;

$blue: #008089;
$blue-green: #126f87;
$blue-dark: #004656;

$blue-grey: #2b2f3e;

$neutral: #00aeff;
$negative: #db4255;
$positive: #7ec783;
