.container {
  display: inline-block;
  background: transparent;
  user-select: none;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: auto;
  padding: 15px 6px;

  z-index: 0;

  .content {
    display: inline-block;
    border-radius: 15px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

    video {
      display: flex;
      border-radius: 15px;
      overflow: hidden;
    }
  }
}

.shake {
  animation: shake 0.2s 3;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
