@import '../styles/colors.scss';

.footer {
  padding: 8vh 4vw;
  background: $blue-grey;
  min-height: 20vh;

  color: #fff;
  text-align: center;

  display: flex;

  flex-flow: row wrap;
  justify-content: space-around;
  outline: 1px dotted #eee;

  section {
    width: 450px;
    text-align: left;
  }

  section:first-child {
    box-sizing: border-box;
    padding-right: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: lighten($blue-grey, 70%);

    &:hover {
      color: #fff;
    }
  }
}

.hide {
  display: none;
}

.linkSection {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  div {
    flex: 1;
    min-width: 10rem;
  }
}
