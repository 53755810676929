.bgOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;

  transition: 1s ease opacity;
  will-change: opacity;
  transform: translateZ(0);
}
