.bottomContent {
  position: absolute;
  padding: 22px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;

  font-size: 75%;

  @media (min-width: 700px) {
    font-size: 100%;
  }
}
