.centerCard {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  background: rgba(255, 255, 255, 1);
  position: absolute;
  z-index: 40;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  animation: overlayIn 0.75s 1;
  animation-fill-mode: both;
}

.cardContent {
  h1 {
    font-size: 2em;
    font-weight: 300;
  }

  h2 {
    font-weight: 300;
    color: rgba(0, 0, 0, 0.5);
    margin: -0.75em 0 0 0;
    padding: 0;
    font-size: 2em;
  }

  @media (max-height: 700px) {
    font-size: 80%;
  }

  @media (max-height: 600px) {
    font-size: 50%;
  }

  .body {
    margin: 0 2rem;

    :nth-child(2) {
      opacity: 0;
    }

    :nth-child(3) {
      opacity: 0;
    }
  }

  .header {
    position: relative;
    height: 18rem;
    padding: 2rem;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;

    user-select: none;
    text-align: center;

    .proximityDemo {
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(0.7);

      @media (min-width: 700px) {
        transform: scale(1);
      }
    }

    .timelineDemo {
      transform: scale(0.75);

      @media (min-width: 700px) {
        transform: scale(0.9);
      }
    }
  }
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;

  z-index: -1;

  video,
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.activeCardContent {
  .body {
    overflow: hidden;

    :nth-child(2) {
      animation-fill-mode: both;
      animation: fadeTextIn 1.25s 1 cubic-bezier(0.66, 0, 0, 1);
      animation-fill-mode: both;
      animation-delay: 0.75s;
      animation-duration: 0.5;
    }

    :nth-child(3) {
      animation-fill-mode: both;
      animation: fadeTextIn 1.25s 1 cubic-bezier(0.66, 0, 0, 1);
      animation-fill-mode: both;
      animation-delay: 1.75s;
      animation-duration: 0.5;
    }

    :nth-child(4) {
      animation-fill-mode: both;
      animation: fadeTextIn 1.25s 1 cubic-bezier(0.66, 0, 0, 1);
      animation-fill-mode: both;
      animation-delay: 2.5s;
      animation-duration: 0.5;
    }
  }
}

.card {
  overflow: hidden;
  border-radius: 8px;
  background-color: #fdfdfd;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 90vw;
  width: 25rem;

  animation: popIn 1.25s 1;
  animation-fill-mode: both;
  animation-delay: 1s;

  button {
    background: transparent;
    border-color: transparent;
    cursor: pointer;

    &:disabled {
      opacity: 0.2;
      cursor: default;
    }
  }
}

.stepper {
  margin: 1rem auto;
  width: 75%;

  > div {
    background-color: transparent;
  }

  button {
    color: rgba(0, 0, 0, 0.5);
  }
}

.dismiss {
  .card {
    animation: popOut 0.5s 1;
    animation-fill-mode: both;
  }

  .overlay {
    animation: overlayOut 0.5s 1;
    animation-fill-mode: both;
  }
}

.actionBar {
  text-align: center;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  width: 100%;
  padding: 1px;
  background-color: rgba(30, 176, 252, 1);

  button {
    margin: auto;
    padding: 1.5rem;
    cursor: pointer;
    width: 100%;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.9);
    text-transform: uppercase;

    &:hover {
      animation: grow 1.25s infinite;
      color: #fff;
    }
  }
}

@keyframes popIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.3);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes popOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  25% {
    transform: scale(1.3);
    opacity: 0.75;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes overlayIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes overlayOut {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeTextIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  75% {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
