.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;

  box-sizing: border-box;
  padding: 2rem 1rem;
  margin: 0 auto;
  align-self: center;
  max-width: 800px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0.5rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

  form {
    flex: 1;
  }
}

.search {
  display: flex;
  align-items: center;
  font-size: 150%;
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 0;
  flex: 1;
  padding: 0;
  margin: 0;

  i {
    height: 100%;
    padding: 0 1rem;
    color: rgba(0, 0, 0, 0.5);
  }

  input {
    border-color: transparent;
    flex: 1;
    padding: 0.25rem;
    font-size: 80%;
  }
}

.button {
  color: #00aeff;
  background: transparent;
  border-color: transparent;
  padding: 0 0.25rem;
  border-radius: 30px;
  cursor: pointer;

  i {
    font-size: 200% !important;
    font-weight: normal;
  }
  &:hover {
    transform: scale(1.1);
  }
}

.clearButton {
  color: rgba(0, 0, 0, 0.5);
  transform: scale(0.5);

  &:hover {
    transform: scale(0.6);
  }
}

.exploreButton {
  margin: 0;

  animation: grow 1.25s infinite;

  &:hover {
    animation: pulse 1.25s 2 cubic-bezier(0.66, 0, 0, 1);
  }
}

.searchInAreaButton {
  animation-fill-mode: both;
  animation: fadeTextIn 1.25s 1 cubic-bezier(0.66, 0, 0, 1);
}

.chip {
  margin: 0.05rem;
  max-width: 30vw;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.loader {
  width: 100%;
  height: 35px;
  text-align: right;

  div {
    margin: 0 !important;
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  from {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  }
  to {
    box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
}
