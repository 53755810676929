@import-normalize;

@import './colors.scss';

html {
  background: #fff;
  -webkit-font-smoothing: subpixel-antialiased;
}

html,
body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 400;
  height: 100%;
  min-height: 100%;
}

:global(.app) {
  height: 100%;
  min-height: 100%;

  & > div {
    height: 100%;
    min-height: 100%;
  }
}

h1 {
  font-weight: 800;
}

h2 {
  font-weight: 600;
}

h4,
h5,
h6 {
  font-weight: 600;
}

p,
li {
  line-height: 150%;
}

a {
  color: $blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

strong {
  font-weight: bold;
  color: $orange-red;
}

form,
input,
button,
textarea {
  outline: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, sans-serif;

  &:active,
  &:focus {
    outline: none;
  }

  &:required {
    box-shadow: none;
  }
}

form {
  input {
    border: none;
    background: rgba(255, 255, 255, 0);
    color: inherit;

    &::placeholder {
      color: inherit;
    }
  }

  label {
    font-size: 100%;
    white-space: nowrap;
    border-bottom: 1px solid #000;
    padding: 0.75rem 0.75rem 0.75rem 0;
    margin: 1rem 0.75rem 1rem 0;

    * {
      display: inline-block;
    }

    i {
      padding: 0;
      font-size: inherit;
      padding-right: 0.75rem;
      height: 17px;
    }
  }

  button {
    cursor: pointer;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
    background: $orange-red;
    border: none;
    margin: 2rem 0;
    padding: 0.75rem 1rem;
    border-radius: 30px;
    transform: scale(1);
    transition: 0.2s ease transform, 0.2s ease box-shadow;

    &:hover {
      transition: 0.2s ease transform, 0.2s ease box-shadow;
      transform: scale(1.1);
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    }
  }
}
