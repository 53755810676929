.container {
  position: relative;
  user-select: none;

  video {
    overflow: hidden;
    width: 178px;
    height: 299px;
  }
}

.notification {
  position: absolute;
  top: 50%;
  left: 100%;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  background-color: #fff;
  padding: 0.5rem;

  width: 15rem;

  text-align: left;
  font-size: 9px;

  border-radius: 10px;
  border: 1px solid #eee;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);

  opacity: 0;

  div > h1 {
    font-size: 11px;
    font-weight: 400;
  }

  i {
    color: #df344a;
    font-size: 2rem;
    padding: 0 5px;
  }
}

.centerNotification {
  left: 50%;
}

.popIn {
  transform-origin: left;
  animation: popIn 1.25s 1;
  animation-fill-mode: both;
  animation-delay: 0.25s;
}

@keyframes popIn {
  0% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
  50% {
    transform: scale(1.3) translate(-50%, -50%);
    opacity: 1;
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
}
