.fullHeight {
  position: relative;
  height: 100vh;
  box-sizing: border-box;

  padding: 0 10vw;
  min-height: 50vh;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > * {
    width: 100%;
  }
}
