@import '../styles/colors.scss';

.header {
  background-color: $neutral;
  padding: 200px 5vw 5vh 5vw;
  min-height: 40vh;
  color: rgba(0, 0, 0, 0.8);

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (min-width: 942px) {
    padding: 200px 25vw 5vh 25vw;
    min-height: 40vh;
  }

  h1,
  h2 {
    text-align: center;
    padding: 0.5vh 2vw;
  }

  p {
    padding: 0.5vh 2vw;
  }

  strong {
    color: black;
  }

  .toc {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & > * {
      margin: 10px 5px;
    }
  }
}

.inApp {
  padding-top: 5vh;
  background-color: #fff;
}

.contentSection {
  position: relative;
  padding: 2rem 11vw;
  font-size: 120%;

  h1 {
    font-weight: 600;
    font-size: 2.2rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  h3 {
    font-size: 1rem;
  }

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  address,
  ul {
    @media (min-width: 700px) {
      margin: 2rem 5vw;
    }
  }

  strong {
    color: black;
  }

  address {
    font-style: normal;
    line-height: 150%;
  }
}
