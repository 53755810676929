.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  margin: 5rem;
  height: 232px;
  width: 399px;

  transform: scale(0.8);

  @media (min-width: 400px) {
    transform: scale(1);
  }
}
