@import '../styles/colors.scss';

.primary {
  min-height: 500px;
  overflow: hidden;
}

.bgOverlay {
  background: url('/img/people2-blur.jpg');
  background-size: cover;
  background-position: center;

  opacity: 1;
  transform: scale(1.2);

  transition: none;
  animation-fill-mode: both;
  animation: simpleFade 2s 1 cubic-bezier(0.66, 0, 0, 1);
}

.mainContent {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  .copy {
    box-sizing: border-box;
    max-width: 500px;
    min-width: 10px;
    font-size: 0.7rem;
    z-index: 5;
    margin-top: -10%;

    text-align: center;

    a {
      color: black;
      text-decoration: underline;
    }

    div > * {
      transform: scale(0.9);
    }

    @media (min-height: 500px) {
      margin-top: -70%;
    }

    @media (min-width: 942px) {
      margin-top: 0;
      text-align: left;
      padding-right: 2rem;
    }

    @media (min-width: 650px) {
      font-size: 1rem;

      div > * {
        transform: none;
      }
    }

    h1::first-line {
      opacity: 1;
    }

    h1 {
      text-transform: uppercase;

      em {
        opacity: 0.6;
        font-style: normal;
        display: block;
        white-space: nowrap;
      }
    }

    p {
      font-size: 1.3em;
      font-weight: 500;
      opacity: 0.8;
      line-height: 1.3em;
    }
  }

  .demo {
    position: absolute;
    top: 55%;

    box-shadow: none;

    background: transparent;
    border: none;
    padding: 0;
    border-radius: 0;

    @media (min-width: 650px) {
      border: 2px solid rgba(255, 255, 255, 0.25);
      padding: 40px 10px;
      border-radius: 30px;
    }

    @media (max-height: 500px) and (max-width: 942px) {
      display: none;
    }

    @media (min-width: 942px) and (max-height: 980px) {
      position: relative;
      top: 10vh;
    }

    @media (min-width: 942px) and (max-height: 830px) {
      top: 25vh;
    }

    @media (min-width: 942px) and (max-height: 600px) {
      top: 40vh;
    }

    @media (min-width: 942px) {
      margin-left: 6rem;
    }

    @media (min-width: 942px) and (min-height: 980px) {
      position: inherit;
    }
  }
}

.timelineDemo {
  display: none;
  border: none;
  border-radius: 15px;
  box-shadow: 0 0 10vw 0.1vw rgba(0, 0, 0, 0.5) !important;

  video {
    /* This simulates the width/height of the unloaded video without
    constraining the asset when it finishes loading */
    max-width: 358px;
    min-height: 599px;

    /* This simulates the first frame's background of the unloaded video
    without requiring a request for a poster asset */
    background-repeat: no-repeat;
    background-image: linear-gradient(to right, #3eb9f5, #3eb9f5),
      linear-gradient(to right, #f6f6f5, #f6f6f5);
    background-position: 0 0, 0 242px;
    background-size: 100% 322px, 100% 358px;
  }
}

.contentSection {
  position: relative;
  padding: 8vh 4vw;

  h1 {
    font-weight: 600;
    font-size: 2.1rem;
  }

  h2 {
    font-size: 1.9rem;
  }

  h3 {
    font-size: 1.7rem;
  }
}

.integrations {
  overflow: hidden;
  text-align: center;
  color: rgba(0, 0, 0, 0.75);
  box-shadow: inset 0 3vh 3vh -3vh rgba(0, 0, 0, 0.3),
    inset 0 -3vh 3vh -3vh rgba(0, 0, 0, 0.3);

  .integrationsGrid {
    flex-flow: row wrap !important;
    margin: 0 auto;
  }

  .integrationsOverlay {
    background: url('/img/hills.jpg');
    background-size: cover;
    background-position: center;

    filter: blur(5px);
    opacity: 1;
    transform: scale(1.2);

    transition: none;
    animation-fill-mode: both;
    animation: simpleFade 2s 1 cubic-bezier(0.66, 0, 0, 1);
  }

  p {
    font-size: 1.3em;
    font-weight: 500;
    opacity: 0.8;
    line-height: 1.3em;
  }

  ul {
    margin: 0;
    height: 8rem;
    box-sizing: border-box;

    list-style-type: none;

    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: center;

    @media (min-width: 700px) {
      max-width: 10vw;
      justify-content: flex-end;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;

      align-self: flex-start;

      min-width: 3rem;
      height: 3rem;
      margin: 0;
      padding: 1rem;

      background: rgba(255, 255, 255, 0.75);
      border-radius: 50%;
    }

    li:nth-child(odd) {
      align-self: flex-end;
    }
  }

  img {
    max-width: 3rem;
    max-height: 3rem;
    object-fit: cover;
    border-radius: 8px;
  }
}

.learn {
  text-align: left;
  overflow: hidden;

  @media (min-width: 700px) {
    text-align: center;
  }
}

.gridSection {
  text-align: left;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  margin: 10vh auto;

  max-width: 1400px;

  &:nth-child(odd) {
    flex-flow: row-reverse wrap;

    .copy {
      @media (min-width: 700px) {
        padding: 0 2.5vw 0 0;
      }
    }
  }

  @media (min-width: 700px) {
    margin: 25vh auto;
    justify-content: space-around;
  }

  @media (min-width: 1024px) {
    justify-content: center;
  }

  .copy {
    padding: 10vh 0 0 0;

    h2,
    h3 {
      margin: 0.5rem 0;
    }

    h3 {
      color: rgba(0, 0, 0, 0.6);
    }

    @media (min-width: 700px) {
      max-width: 250px;
      padding: 0 0 0 2.5vw;
    }

    @media (min-width: 825px) {
      max-width: 350px;
    }
  }

  .callout {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 50%;
    max-width: 100vh;

    @media (min-width: 700px) {
      max-width: 50%;
    }

    .bg {
      position: absolute;
      top: 0;
      left: -10vw;
      bottom: 0;
      right: 0;
      width: 100vh;

      user-select: none;
      overflow: hidden;

      z-index: -1;

      video,
      img {
        object-fit: cover;
        object-position: left top;
        height: 100%;
        width: 100%;
      }

      @media (min-width: 700px) {
        right: inherit;
        bottom: inherit;

        width: 100%;
        height: 400px;
      }
    }
  }
}

.getStarted {
  color: rgba(0, 0, 0, 0.75);
  text-align: center;

  .appSelector {
    max-width: 22rem;
    margin: 0 auto;
  }
}

@keyframes simpleFade {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1.2);
  }
}
