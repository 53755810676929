@import '../styles/colors.scss';

$navbar-collapse-width: 650px;
$navbar-collapse-height: 500px;

.hide {
  display: none;
}

.appNav {
  position: absolute;
  top: 0;

  /* Keep menu above main content, which has z-index to hover over graph */
  z-index: 10;

  width: 100%;
  padding: 1rem 4vw;

  background: #fff;

  box-sizing: border-box;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transition: 0.4s ease padding, 0.7s ease background;

  .toggle {
    background: transparent;
    font-size: 130%;
    border: none;

    &:focus {
      outline: none;
    }
  }

  a,
  a:link,
  a:hover,
  a:visited,
  a:active {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.75);
  }

  a:hover,
  a:active {
    color: rgba(0, 0, 0, 0.5);
  }

  .menu {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: 600;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: 0.7s ease-in max-height, 0.7s ease-in overflow;
    max-height: 50vh;
    overflow: hidden;

    li {
      vertical-align: middle;
      display: flex;
      align-items: right;
    }

    a {
      padding: 0.3rem;
    }
  }

  .brand {
    text-align: center;
    margin: 0;
    padding: 0;

    img {
      max-width: 9rem;
    }

    a {
      min-width: 80vw;
    }

    a:hover,
    a:active {
      color: rgba(0, 0, 0, 0.75);
    }
  }
}

.appNavClosed {
  @media (max-width: $navbar-collapse-width),
    (max-height: $navbar-collapse-height) {
    flex-direction: row;
    background: rgba(255, 255, 255, 0);

    .menu {
      transition: 0.5s ease-out max-height;
      max-height: 0;
    }
  }
}

@media (min-width: $navbar-collapse-width) and (min-height: $navbar-collapse-height) {
  .appNav {
    position: fixed;
    flex-flow: row nowrap;
    align-items: center;
    transition: 0.4s ease padding, 1s ease background-color,
      0.4s ease box-shadow;

    background-color: transparent;
    box-shadow: 0 0 0 0 transparent;

    padding: 5rem 10vw;

    .toggle {
      display: none;
    }

    .menu {
      flex-direction: row;
      justify-content: flex-end;

      transition: none;
      overflow: inherit;

      li {
        padding-left: 1rem;
      }
    }
  }

  .active {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid #eee;
    box-shadow: 0 -1rem 2rem rgba(0, 0, 0, 0.5);
    background-color: rgba(255, 255, 255, 0.9);
    transition: 0.4s ease padding, 1s ease background-color, 1s ease box-shadow;
  }
}
