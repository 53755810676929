.container {
  position: relative;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background-color: #fff;

  text-align: left;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  z-index: 10;

  width: 30em;

  .content {
    padding: 2em 1em;
    z-index: 10;

    & > h1 {
      margin: 0 0 0.3em;
      font-weight: 400;
    }
  }
}

.background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-image: url('/img/people2.jpg');
  background-size: cover;
  background-position: -4rem 3rem;
  filter: blur(20px);
  z-index: 5;
}

.iconArea {
  position: relative;
  z-index: 10;

  background-color: #666;
  display: flex;
  align-items: center;
  max-width: 20%;
  text-align: center;
  color: #fff;
  padding: 1em;
  font-weight: 500;

  i {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.225;
    justify-content: space-around;
    align-items: center;
    font-size: 5.5em;
  }
}

.positive {
  .iconArea {
    background-color: #49cc52;
  }
}

.negative {
  .iconArea {
    background-color: #df344a;
  }
}

.neutral {
  .iconArea {
    background-color: #00aeff;
  }
}
