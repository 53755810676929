.container {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: 100%;

  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;

  .placeStack {
    margin: 5rem;
    transform: scale(0.8);

    & > :nth-child(3) {
      box-shadow: 0 0 5rem 0 rgba(0, 0, 0, 0.5);
      margin: 0;
    }
    & > :nth-child(2) {
      box-shadow: 0 0 2.5rem 0 rgba(0, 0, 0, 0.5);
      margin: 0 0 -6rem 0;
    }
    & > :nth-child(1) {
      box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.5);
      margin: 0 0 -6rem 0;
    }
  }
}
