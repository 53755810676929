.container {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  color: inherit;
  width: 100%;

  /* Above main content text, below main nav */
  z-index: 8;

  user-select: none;

  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    display: block;
    width: auto;
    padding: 1rem;

    color: inherit;
    background: rgba(255, 255, 255, 0);

    border-radius: 50% 50% 0 0;

    transition: 1s ease background, 1s ease padding;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      padding: 1rem 1rem 0.25rem;
      text-decoration: none;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  i {
    font-size: 120%;
  }
}

.darken {
  a:hover,
  a:active {
    background: rgba(0, 0, 0, 0.1);
  }
}
